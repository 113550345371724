import './PremiumHomeContent.css';
import trading from '../../assets/tradnig.png';
import videoMob from '../../assets/welcomeMob.mp4';
import video from '../../assets/welcome.mp4';
import { DefaultPlayer as Video } from 'react-html5video';
import poster from '../../assets/poster.jpg';
import 'react-html5video/dist/styles.css';
import scrollToElement from '../scrollToElement';

const PremiumHomeContent = () => {
  const isMobile = window.innerWidth <= 820;
  return (
    <section className="premium-content">
      <img src={trading} alt="Trading for everyone" />
      {/* <p className="premium-description ">
        User-Friendly AI Tool For Succesful Trading
      </p> */}
      <div className="premium-wrapper">
      <button className="get-access green-btn" onClick={() => scrollToElement('premium')}>
  Get Profit
</button>
        {isMobile ? (
          <Video
            className="premium-video"
            controls={[]}
            poster={poster}
          >
            <source src={videoMob} type="video/mp4" />
          </Video>
        ) : (
          <Video
            className="premium-video"
            controls={[]}
            poster={poster}
          >
            <source src={video} type="video/mp4" />
          </Video>
        )}
         {/* <p className="premium-description ">
        Welcome to the future of trading! <br></br>I'm Korax, your personal trading AI assistant
      </p> */}
      </div>
    </section>
    
  );
};

export default PremiumHomeContent;
