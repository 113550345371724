import { useState } from 'react';
import { motion } from 'framer-motion';
import statisticImg from '../../assets/statistic-img.png';
import statistic from '../../assets/statistic.png';
import statisticImgMob from '../../assets/statistic-img-mob.png';
import './StatisticContant.css';
import Modal from 'react-bootstrap/Modal';

const StatisticContant = () => {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  const isMobile = window.innerWidth <= 820;

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  return (
    <section className="statistic">
      {/* <motion.div
        initial={{ width: 0 }}
        whileInView={{ width: '100%' }}
        transition={{ duration: 1 }}
        className="border-dashed mb-5"
      ></motion.div> */}
      <h2 className="statistic-title">
        Last Month's Statistics for <br />
        "Trading For Everyone"
      </h2>
      {/* <motion.div
        initial={{ width: 0 }}
        whileInView={{ width: '100%' }}
        transition={{ duration: 1 }}
        className="border-dashed mb-5"
      ></motion.div> */}
      {!isMobile ? (
        <div className="statistic-responsive">
          <button onClick={() => handleShow(true)}>
            <img
              className="statistic-img"
              src={statistic}
              alt="Statistic"
            />
          </button>

          <Modal
            className="modal-statistic"
            show={show}
            fullscreen={fullscreen}
            onHide={() => setShow(false)}
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <img
                style={{ width: '100%' }}
                src={statisticImg}
                alt="Statistic"
              />
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <div className="statistic-responsive">
          <img
            style={{ width: '100%' }}
            src={statisticImgMob}
            alt="Statistic"
          />
        </div>
      )}
      {/* <div className="centered-text">
      <p>In last month Korax AI showed a</p>
    </div>
        <p className="statistic-text">SAFE PROFIT of <span>+27.11 %</span>
      </p>       */}
      <motion.div
          initial={{ width: 0 }}
          whileInView={{ width: '100%' }}
          transition={{ duration: 1 }}
          className="border-dashed"
        ></motion.div>
    </section>
  );
};

export default StatisticContant;
