import React from "react";
import "./TrustPilot.css";
import { Container, Row, Col } from "react-bootstrap";
import SwiperCore, {
  Autoplay,
  Pagination,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay]);

const TrustMobile = () => {
  return (
    <Container className="mt-5">
      <Swiper
        className="swiper-container"
        spaceBetween={10}
        slidesPerView={1}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination
        modules={[Autoplay, Pagination]}
      >
      <Row className="trust-slide-wrapper ">
        <SwiperSlide>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <div className="pull-left rating d-flex justify-content-center">
                    <img
                      alt="#"
                      src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg"
                      className="img-responsive"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="trust-title text-center">
                  Long term growth
                  </div>
                  <div className="trust-descriptions text-center">
                  I have lots of faith in copying their trades for the long term. I have seen the results they are capable of during my time with them so far, look forward to the future!
                  </div>
                  <div className="trust-name text-center">Imogen</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <div className="pull-left rating d-flex justify-content-center">
                    <img
                      alt="#"
                      src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                      className="img-responsive"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="trust-title text-center">
                  Got to give credit where its due !!
                  </div>
                  <div className="trust-descriptions text-center">
                  I sit back and let the pros take care of the trading as I have done from the start. Works for me! There may be ups and downs but my money is up overall since i started.
I don't tell the weatherman when its gonna rain or shine, I don't tell sparkys how to wire up houses, and i don't tell these lot how to trade. Let them take care of it for me and they get the job done.
                  </div>
                  <div className="trust-name text-center">Andrew Boyce</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <div className="pull-left rating d-flex justify-content-center">
                    <img
                      alt="#"
                      src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_4star-RGB.png"
                      className="img-responsive"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="trust-title text-center">
                  Great service
                  </div>
                  <div className="trust-descriptions text-center">
                    Great team, simple and very effective trading programme. Been with them for almost 1 months. I've ups and downs but i've profit overall since i started 1 month ago. 
                  </div>
                  <div className="trust-name text-center">Shel MF</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <div className="pull-left rating d-flex justify-content-center">
                    <img
                      alt="#"
                      src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                      className="img-responsive"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="trust-title text-center">
                  Korax AI is HANDS DOWN the best…
                  </div>
                  <div className="trust-descriptions text-center">
                    I've searched for 6 years for anything that can make passive income to start my journey with making money and I have finally come across alpha. You are single handidly changing mine and other peoples lives by giving us this service and I can truly not thank you enough god bless🙏🏽
                  </div>
                  <div className="trust-name text-center">Tom</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <div className="pull-left rating d-flex justify-content-center">
                    <img
                      alt="#"
                      src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                      className="img-responsive"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="trust-title text-center">
                  Excellent service
                  </div>
                  <div className="trust-descriptions text-center">
                    I have to say, I was always very sceptical when it came to trading, as I didn’t quite understand it. It goes without saying with trading, you win some you lose some, but the wins outweigh the losses with Korax AI and I’m always in profit and making compound interest daily.
                  </div>
                  <div className="trust-name text-center">David</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <div className="pull-left rating d-flex justify-content-center">
                    <img
                      alt="#"
                      src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                      className="img-responsive"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="trust-title text-center">
                  Fabulous
                  </div>
                  <div className="trust-descriptions text-center">
                  Started used it then slowly slowly it garnered more results the average results it was nearly doubling my money every 2 months and good RoI
                  </div>
                  <div className="trust-name text-center">Dishant Babel</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SwiperSlide>
        <SwiperSlide>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <div className="pull-left rating d-flex justify-content-center">
                    <img
                      alt="#"
                      src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                      className="img-responsive"
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="trust-title text-center">
                    I have tested and will purchase
                  </div>
                  <div className="trust-descriptions text-center">
                  I have tested and purchased various crypto trading bots, but this AI surpasses them all. I'm currently using the 7-day trial and have already made a significant percentage of profit.
                  </div>
                  <div className="trust-name text-center">Maurice P.</div>
                </Col>
              </Row>
            </Col>
          </Row>
        </SwiperSlide>
        </Row>
      </Swiper>
    </Container>
  );
};

export default TrustMobile;
