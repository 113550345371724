import { useState, useRef, useEffect } from 'react';
import './SubscriptionContent.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// import { motion } from 'framer-motion';
import { Pagination } from 'swiper';
import TransactionWindow from '../TransactionWindow/TransactionWindow';
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
// import TransactionWindowFree from '../TransactionWindow/TransactionWindowFree';
import scrollToElement from '../scrollToElement';

const SubscriptionContent = () => {
  const [modalShow, setModalShow] = useState(false);
  // const [modalShowFree, setModalShowFree] = useState(false);
  const [typeSubscr, setTypeSubscr] = useState('');

  const [number, setNumber] = useState(
    Number(localStorage.getItem('number')) || 87
  );

  const [statusForCheckToken, setStatusForCheckToken] = useState({})

  const [showModalForCheck, setShowModalForCheck] = useState(false);
  const handleCloseForCheck = () => setShowModalForCheck(false);
  const handleShowForCheck = () => setShowModalForCheck(true);

  useEffect(() => {
    if (number > 4) {
      const delay =
        Math.floor(Math.random() * (10000 - 6000 + 1)) + 6000;
      console.log(delay);
      const interval = setInterval(() => {
        setNumber((prevNumber) => {
          const newNumber = Math.floor(Math.max(prevNumber - 1, 0));
          localStorage.setItem('number', newNumber);

          return newNumber;
        });
      }, delay);

      return () => clearInterval(interval);
    } else return;
  }, [number]);

  const tagRefs = {
    first: useRef(null),
    second: useRef(null),
    third: useRef(null),
  };
  const isMobile = window.innerWidth <= 820;

  const handleButtonClick = (cardType) => {
    const tagRef = tagRefs[cardType];
    setModalShow(true);
    if (tagRef.current && tagRef.current.textContent) {
      setTypeSubscr(tagRef.current.textContent);
    }
  };
  // const handleButtonClickFree = (cardType) => {
  //   const tagRef = tagRefs[cardType];
  //   setModalShowFree(true);
  //   if (tagRef.current && tagRef.current.textContent) {
  //     setTypeSubscr(tagRef.current.textContent);
  //   }
  // };

  return (
    <div className="subscription" id="premium">
      <h2 className="subscription-title">Subscription Plans</h2>
      <p className="subscription-text">
        Choose the plan that works for you
      </p>
      {/* <p className="subscription-offer">
        Limited opening offer for 100 people /{' '}
        <span className="offer-red">{number} left </span>
      </p> */}
      {isMobile ? (
        <Swiper
          slidesPerView={1.5}
          spaceBetween={10}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          initialSlide={1}
          modules={[Pagination]}
          className="subscription-wrapper mySwiper"
        >
          <SwiperSlide className="subscription-card first-card">
            <div className="subscription-content">
              <p className="subscription-days first">
              <p className="uniq-offer">"Trading For Everyone"</p>
                1 Month Subscription
              </p>
              <h2 ref={tagRefs.first}>
              $50{' '}
                <span className="slash">
                  / <span className="old-price">79$</span>
                </span>
              </h2>
              <p className="subscription-limited">
                Limited discount
              </p>
              <button
                onClick={() => handleButtonClick('first')}
                className="subscription-button"
              >
                Get Premium
              </button>
              <button
                onClick={() => scrollToElement("calculator")}
                className="subscription-link"
                style={{background:'transparent'}}
              >
                How much do I earn ?
              </button>
              <ul className="subscription-list" onClick={() => scrollToElement('premium')}>
                <li>Full scope AI Crypto Signals</li>
                <li>AI Urgent News</li>
                <li>Technical AI Analysis Market</li>
                <li>Personal Premium Support</li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide className="subscription-card second-card">
            <div className="subscription-content">
              <p className="subscription-days second">
              <p className="uniq-offer">"Trading For Everyone"</p>
              3 Month Subscription{' '}
              </p>
              {/* <p className="uniq-offer">Uniq offer</p> */}
              <h2 ref={tagRefs.second}>
              $150{' '}
                <span className="slash">
                  / <span className="old-price">250$</span>
                </span>
              </h2>
              <p className="subscription-limited">
              Limited discount
              </p>
              <button
                onClick={() => handleButtonClick('second')}
                className="subscription-button"
              >
                Get Premium
              </button>
              <button
                onClick={() => scrollToElement("calculator")}
                className="subscription-link"
                style={{background:'transparent'}}
              >
                How much do I earn ?
              </button>
              <ul className="subscription-list">
                <li>Full scope AI Crypto Signals</li>
                <li>AI Urgently News</li>
                <li>Technical AI Analysis Market</li>
                <li>Support</li>
              </ul>
            </div>
          </SwiperSlide>
          <SwiperSlide className="subscription-card third-card">
            <div className="subscription-content">
              <p className="subscription-days third">
                API - Korax AI
              <br/>1 Yearly Support
              </p>
              <h2 ref={tagRefs.third}>
                $2500{' '}
                <span className="slash">
                  {/* / <span className="old-price">1000$</span> */}
                </span>
              </h2>
              <p className="subscription-limited">
              (Connection with Binance)*
              </p>
              <button
                onClick={() => handleButtonClick('third')}
                className="subscription-button"
              >
                Get Premium
              </button>
              {/* <a href="#statistic" className="subscription-link">
                Show last month PNL
              </a> */}
              <ul className="subscription-list">
              <br/><br/><li>API - Korax AI </li>
                <li>Unlimited API Calls</li>
                <li>Technical Support</li>
                <li>Personal Premium Support</li>
              </ul>
            </div>
          </SwiperSlide>
        </Swiper>
      ) : (
        <div className="subscription-wrapper">
          <div className="subscription-card first-card">
            <div className="subscription-content">
              <p className="subscription-days first">
              <p className="uniq-offer">"Trading For Everyone"</p>
                1 Month Subscription
              </p>
              <h2 ref={tagRefs.first}>
                $50{' '}
                <span className="slash">
                  / <span className="old-price">79$</span>
                </span>
              </h2>
              <p className="subscription-limited">
                Limited discount
              </p>
              <button
                onClick={() => handleButtonClick('first')}
                className="subscription-button"
              >
                Get Premium
              </button>
              <button
                onClick={() => scrollToElement("calculator")}
                className="subscription-link"
                style={{background:'transparent'}}
              >
                How much do I earn ?
              </button>
              <ul className="subscription-list">
                <li>Full scope AI Crypto Signals</li>
                <li>AI Urgent News</li>
                <li>Technical AI Analysis Market</li>
                <li>Personal Premium Support</li>
              </ul>
            </div>
          </div>
          <div className="subscription-card second-card">
            <div className="subscription-content">
              <p className="subscription-days second">
               <p className="uniq-offer">"Trading For Everyone"</p>
                3 Month Subscription{' '}
              </p>
              {/* <p className="uniq-offer">'Trading For Everyone'</p> */}
              <h2 ref={tagRefs.second}>
                $150{' '}
               <span className="slash">
                  / <span className="old-price">250$</span>
                </span>
              </h2>
              <p className="subscription-limited">
              Limited discount 
              </p>
              <button
                onClick={() => handleButtonClick('second')}
                className="subscription-button"
              >
                Get Premium
              </button>
              <button
                onClick={() => scrollToElement("calculator")}
                className="subscription-link"
                style={{background:'transparent'}}
              >
                How much do I earn ?
              </button>
              <ul className="subscription-list">
                <li>Full scope AI Crypto Signals</li>
                <li>AI Urgently News</li>
                <li>Technical AI Analysis Market</li>
                <li>Support</li>
              </ul>
            </div>
          </div>
          <div className="subscription-card third-card">
            <div className="subscription-content">
              <p className="subscription-days third">
              API - Korax AI
              <br/>1 Yearly Support
              </p>
              <h2 ref={tagRefs.third}>
                $2500{' '}
                <span className="slash">
                  {/* / <span className="old-price">1000$</span> */}
                </span>
              </h2>
              <p className="subscription-limited">
              (Connection with Binance)*
              </p>
              <button
                onClick={() => handleButtonClick('third')}
                className="subscription-button"
              >
                Get Premium
              </button>
              {/* <a href="#statistic" className="subscription-link">
                Show last month PNL
              </a> */}
              <ul className="subscription-list">
                <br/><br/><li>API - Korax AI</li>
                <li>Unlimited API Calls</li>
                <li>Technical Support</li>
                <li>Personal Premium Support</li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <div>
        <Modal show={showModalForCheck} onHide={handleCloseForCheck} size="lg" centered>
          <Modal.Body closeButton>
    <pre className={"text-center"} style={{ whiteSpace: "pre-wrap", color: "black" }}>
      {statusForCheckToken?.message || ""}
    </pre>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button variant="secondary" onClick={handleCloseForCheck}>Close</Button>
          </Modal.Footer>
        </Modal>

      </div>
      <TransactionWindow
        type={typeSubscr}
        show={modalShow}
        onHide={() => setModalShow(false)}
        handleShowForCheck={handleShowForCheck}
        setStatusForCheckToken={setStatusForCheckToken}
      />
      {/* <TransactionWindowFree
        type={typeSubscr}
        show={modalShowFree}
        onHide={() => setModalShowFree(false)}
      /> */}
    </div>
  );
};

export default SubscriptionContent;
