import React from "react";
import "./TrustPilot.css";
import { Container, Row, Col } from "react-bootstrap";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import TrustMobile from "./TrustMobile";
import { motion } from 'framer-motion';

SwiperCore.use([Autoplay, Navigation]);

const TrustPilot = () => {
  const isMobile = window.innerWidth <= 992;

  return (
      <Container className="trustpilot-widget">
        <Row className="trustpilot-wrapper">
          <Col className="rating-wrapper" lg={2} md={6}>
            <div className="wrapper-left">
              <h3 className="rating-title">Excellent</h3>
              <div className="rating-img">
                <img
                  alt="#"
                  src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-4.5.svg"
                  className="rating-img-responsive"
                />
              </div>
              <p className="rating-info">Based on 902 reviews</p>
            </div>
          </Col>
          <Col md={9} className="reviews">
            {!isMobile ? (
              <Swiper
                id="Carousel"
                className="swiper-container"
                spaceBetween={10}
                slidesPerView={1}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                navigation
                modules={[Autoplay, Navigation]}
              >
                <SwiperSlide>
                  <Row className="trust-slide-wrapper">
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <div className="pull-left rating">
                            <img
                              alt="#"
                              src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                              className="img-responsive"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="trust-title">
                          Long term growth
                          </div>
                          <div className="trust-descriptions">
                          I have lots of faith in copying their trades for the long term. I have seen the results they are capable of during my time with them so far, look forward to the future!
                          </div>
                          <div className="trust-name">Imogen</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <div className="pull-left rating">
                            <img
                              alt="#"
                              src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                              className="img-responsive"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="trust-title">
                          Korax AI top 
                          </div>
                          <div className="trust-descriptions">
                          Доволен сервисом, c первого месяца пошла прибыль, не обещают миллионы, но стабильно делают +, в отличии от других. Рекомендую !!
                          </div>
                          <div className="trust-name">Andrey Mikonov</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <div className="pull-left rating">
                            <img
                              alt="#"
                              src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                              className="img-responsive"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="trust-title">
                          Korax AI is HANDS DOWN the best…
                          </div>
                          <div className="trust-descriptions">
                          I've searched for 6 years for anything that can make passive income to start my journey with making money and I have finally come across alpha. You are single handidly changing mine and other peoples lives by giving us this service and I can truly not thank you enough god bless🙏🏽
                          </div>
                          <div className="trust-name">Tom</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <div className="pull-left rating">
                            <img
                              alt="#"
                              src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_4star-RGB.png"
                              className="img-responsive"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="trust-title">
                          Great service
                          </div>
                          <div className="trust-descriptions">
                          Great team, simple and very effective trading programme. Been with them for almost 1 months. I've ups and downs but i've profit overall since i started 1 month ago. 
                          </div>
                          <div className="trust-name">Shel MF</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </SwiperSlide>

                <SwiperSlide>
                  <Row className="trust-slide-wrapper">
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <div className="pull-left rating">
                            <img
                              alt="#"
                              src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                              className="img-responsive"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="trust-title">
                          Excellent service
                          </div>
                          <div className="trust-descriptions">
                          I have to say, I was always very sceptical when it came to trading, as I didn’t quite understand it. It goes without saying with trading, you win some you lose some, but the wins outweigh the losses with Korax AI and I’m always in profit and making compound interest daily.
                          </div>
                          <div className="trust-name">David</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <div className="pull-left rating">
                            <img
                              alt="#"
                              src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                              className="img-responsive"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="trust-title">
                          Fabulous
                          </div>
                          <div className="trust-descriptions">
                          Started used it then slowly slowly it garnered more results the average results it was nearly doubling my money every 2 months and good RoI
                          </div>
                          <div className="trust-name">Dishant Babel</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <div className="pull-left rating">
                            <img
                              alt="#"
                              src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                              className="img-responsive"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="trust-title">
                          I have tested and will purchase
                          </div>
                          <div className="trust-descriptions">
                          I have tested and purchased various crypto trading bots, but this AI surpasses them all. I'm currently using the 7-day trial and have already made a significant percentage of profit.
                          </div>
                          <div className="trust-name">Maurice P.</div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={12}>
                          <div className="pull-left rating">
                            <img
                              alt="#"
                              src="https://images-static.trustpilot.com/supportcenter/Trustpilot_ratings_5star-RGB.png"
                              className="img-responsive"
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="trust-title">
                          Who doesn't love making passive income
                          </div>
                          <div className="trust-descriptions">
                          Sign up is completely hassle free takes no more than 10 minutes and is a great way to invest some spare money!
Signed up when it first started out and in 5 months had a 100% return on my investment!

Cracking team always on hand to answer any questions aswell!
                          </div>
                          <div className="trust-name">Stiven</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </SwiperSlide>
              </Swiper>
            ) : (
              <TrustMobile />
            )}
          </Col>
        </Row>
        <motion.div
        initial={{ width: 0 }}
        whileInView={{ width: "100%" }}
        transition={{ duration: 0.7 }}
        className="border-dashed mb-9"
        style={{ marginBottom: "30px", marginTop: "30px" }}
        // viewport={{once: true}}
      ></motion.div>
      </Container>
  );
};

export default TrustPilot;
