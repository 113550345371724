import Calculator from './Calculator';
import './CalculatorContent.css';

const CalculatorContent = () => {
  return (
    <div id="calculator" className="calculator-content">
      <div className='calculator-content-text'>
        <h2 className="calculator-title">
        How much do{' '} {' '}
          <span style={{ color: '#2ccd7e' }}>I earn</span>
          <span style={{ color: '#ffff' }}> ?</span>
        </h2>
        <p className="calculator-text">
          We uses SAFE PROFIT last month: </p>
          <p className="calculator-text2">
          May {' '}
          <span style={{ color: '#2ccd7e' }}> +36.11 %/month. *</span>
        </p>
      </div>
      <Calculator />
    </div>
  );
};

export default CalculatorContent;
