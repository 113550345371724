import React from 'react';
import { useState } from 'react';
import './TransactionWindow.css';
import { useParams } from "react-router-dom";
import axios from 'axios';

const TransactionForm = ({ type, statusform, setStatusForCheckToken, handleShow }) => {
  const { token } = useParams();

  const formInitial = {
    nickname: '',
    id: '',
  };
  const [formDetails, setFormDetails] = useState(formInitial);
  const [buttonText, setButtonText] = useState('Confirm Payment');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText('Sending...');
    const requestData = { ...formDetails, type };
    const requestBody = JSON.stringify(requestData);


    let response = await fetch(
      'https://korax-back.onrender.com/subscription',
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: requestBody,
      }
    );
    setButtonText('Confirm Payment');
    let result = await response.json();
    setFormDetails(formInitial);
    if (result.code === 200) {
      statusform(true)
    } else {
      setStatus({
        success: false,
        message: 'Something went wrong, please try again later',
      });
    }
    if (token){
      const requestBodyForBot = ({
        telegram_nickname: formDetails.nickname,
        transaction_id: formDetails.id,
        token: token,
        traffic: "site"
      })
      try {
        const responseCheck = await axios.post(
            'https://korax.pythonanywhere.com/siteData',
            requestBodyForBot,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
        );

        handleShow()

        // console.log("TTT",responseCheck)

        // let resultCheck = await responseCheck.json();

        if (responseCheck.status === 200) {
          setStatusForCheckToken({
            success: true,
              message:
                  <>
                      ✅ Your data has been sent successfully! <br/>
                      Follow this link to the bot and it will give you access to Premium subscription:
                      <br/>
                      <br/>
                      <div style={{color: "#0000FF", textDecoration: "none"}}>
                          👉<a style={{color: "#0000FF", textDecoration: "none"}} href="https://t.me/Tydtdt_bot" target="_blank" rel="noopener noreferrer">
                          https://t.me/Tydtdt_bot
                      </a>
                      </div>
                      <br/>
                      <strong>Attention!</strong>
                      <br/>
                      Run the bot only from the Telegram account that you specified, otherwise the bot will NOT provide you
                      with the link.
                  </>
          })
        } else if (responseCheck.status === 401) {
          setStatusForCheckToken({
            success: false,
            message: 'The transaction has not been successful or has not yet been confirmed. Send the hash of the transaction again in a few minutes.',
          });
        }
        else {
          setStatusForCheckToken({
            success: false,
            message: 'Something went wrong, please try again later',
          });
        }
        handleShow()

      } catch (error) {
        setStatusForCheckToken({
          success: false,
          message: 'Something went wrong, please try again later',
        });
        handleShow()

      }

    }
  };
  return (
    <>
    <form className="transaction-form" onSubmit={handleSubmit}>
      <div className="transaction-wrapper">
        <label>
          {' '}
          Your Telegram Nickname*
          <div>
            <input
              value={formDetails.nickname}
              onChange={(e) =>
                onFormUpdate('nickname', e.target.value)
              }
              className="transaction-input"
              type="text"
              required
            />
          </div>
        </label>
      </div>
      <div className="transaction-wrapper">
        <label>
          {' '}
          Your Transaction ID*
          <div>
            <input
              value={formDetails.id}
              onChange={(e) => onFormUpdate('id', e.target.value)}
              className="transaction-input"
              type="text"
              required
            />
          </div>
        </label>
      </div>
      {status.message && (
        <p style={{ color: '#161616' }} className="mb-3">
          {status.message}
        </p>
      )}
      <button className="transaction-button" type="submit">
        <span>{buttonText}</span>
      </button>
    </form>
    </>
  );
};

export default TransactionForm;
